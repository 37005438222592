import React from "react";
import { graphql } from "gatsby";

import Layout from "../layouts/default";
import SEO from "../utils/seo";
import BlockContent from "@sanity/block-content-to-react";
import BlockContentImage from "../components/BlockContentImage";
import TemplateTitle from "../components/TemplateTitle";
import TemplateBackButton from "../components/TemplateBackButton";
import TopicLink from "../components/TopicLink";

import "../styles/style-normal.css";

const TopicPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={`${data.topic.name} | Topics`}
        image={
          data.topic.mainImage
            ? data.topic.mainImage.asset.url + "?h=400"
            : null
        }
        article={true}
      />
      <TemplateTitle
        image={data.topic.mainImage ? data.topic.mainImage.asset.fluid : null}
        color={data.topic.color.hex}
      >
        <TemplateBackButton title="Topics" parent="/topics" />
        <h1
          className="md:w-3/4 w-full inline md:text-5xl text-4xl font-serif break-words leading-snug"
          title="title-main"
          style={{ color: data.topic.color.hex }}
        >
          {data.topic.name}
        </h1>
        <p className="mt-3">{data.topic.description}</p>
      </TemplateTitle>
      <div className="md:w-3/4 w-full mx-auto px-6 py-12">
        <section className="mb-12">
          <h1 className="text-heading">Information</h1>
          <article className="style-normal">
            <BlockContent
              blocks={data.topic._rawBody || []}
              serializers={{
                types: { captionedImage: BlockContentImage },
              }}
              projectId={process.env.GATSBY_SANITY_ID}
              dataset={process.env.GATSBY_SANITY_DATASET}
            />
          </article>
        </section>
        {data.topic.links.length > 0 ? (
          <section className="">
            <h1 className="text-heading">Links</h1>
            {data.topic.links.map((link) => (
              <TopicLink {...link} />
            ))}
          </section>
        ) : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TopicPageQuery($id: String) {
    topic: sanityTopic(_id: { eq: $id }) {
      name
      mainImage {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
          url
        }
      }
      color {
        hex
      }
      description
      _rawBody
      links {
        desc
        title
        url
      }
    }
  }
`;

export default TopicPage;
