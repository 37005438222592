import React from "react";

const TopicLink = (props) => {
  return (
    <div>
      <a className="text-primary text-2xl" href={props.url}>
        {props.title}
      </a>
      <p className="mt-3 mb-6">{props.desc}</p>
    </div>
  );
};

export default TopicLink;
